<template>
  <v-main id="lunch">
    <v-container v-if="loading" class="mb-12">
      <div class="text-center">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    
    </v-container>
    <div v-else-if="loaded">
      <div v-if="response.metadata">
        <v-parallax class="parallax" light :src="parallax" height="250">
          <v-layout align-center column justify-center>
            <h2 class="no-margin white--text modernline text-h2">Malice</h2>
            <span class="text-center">
              Od torka do petka od 10:00 - 13:00
              <br />
              Cena dnevne malice: {{ response.metadata.price }} €
              <br />
              Cena malice po izbiri: {{ response.metadata.price_2 }} €
            </span>
          </v-layout>
        </v-parallax>
        <v-container>
          <v-layout fluid class="mb-6">
            <v-flex>
              <v-layout class="row">
                <v-flex v-for="(day, i) in items" :key="`${i}`">
                  <v-card flat tile>
                    <v-card-title class="text-center day-title">
                      <h5 class="modernline">{{ day.title | capitalize }}</h5>
                      <h6>{{ day.subtitle }}</h6>
                    </v-card-title>
                    <v-card-text primary-title>
                      <p
                        class="lunch"
                        style="white-space: break-spaces"
                        v-html="day.content"
                      ></p>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <div v-else>
        <v-parallax class="parallax" light :src="parallax" height="250">
          <v-layout align-center column justify-center>
            <h2 class="no-margin white--text modernline text-h2">Malice</h2>
            <p v-html="response.content"></p>
          </v-layout>
        </v-parallax>
      </div>
    </div>
  </v-main>
</template>

<script>
import { createBucketClient } from "@cosmicjs/sdk";
import moment from "moment";

const cosmic = createBucketClient({
  bucketSlug: "menciger-production",
  readKey: "3320E6EqWD3o37P4Wy4VC6W2viTpCJCVYM8xNJ3HRxk592sGrZ",
});
export default {
  data() {
    return {
      parallax: require("../assets/content/lunch/title.webp"),
      response: {},
      items: [],
      loading: true,
      loaded: false,
    };
  },
  async mounted() {
    moment.locale("sl");
    const currentWeek =
      moment().weekday() === 6
        ? moment().add(1, "days")
        : moment().startOf("isoweek");

    const data = await cosmic.objects
      .findOne({ type: "malice", slug: currentWeek.format("YYYY-MM-DD") })
      .props("metadata");

      if (!data.object) {
        this.loading = false;
      return;
    }

    this.response = data.object;
    const day = ["monday", "tuesday", "wednesday", "thursday", "friday"];
    let currentDate = null;
    const processedData = [];
    day.forEach((element, index) => {
      currentDate = moment(currentWeek).add(index, "days");
      processedData.push({
        subtitle: currentDate.format("DD.MM."),
        title: moment(currentDate).format("dddd"),
        content: this.response.metadata[element],
      });
    });
    if (this.response.metadata.special_offer) {
      processedData.push({
        title: "Posebna ponudba",
        subtitle: "Vsak dan",
        content: this.response.metadata.special_offer,
      });
    }
        this.loading = false;
    this.loaded = true;
    this.items = processedData;

    // })
    // .catch(() => {
    //   this.loading = false;
    // });
  },
};
</script>

<style scoped>
.day-title h5 {
  font-size: 1.7rem;
  line-height: 2.5rem;
  width: 100%;
}
.day-title h6 {
  font-weight: 300;
  font-size: 1rem;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.active {
  background-color: #f5f5f5;
  border-bottom: 2px solid #daa520;
}
.lunch {
  font-family: "Lato", sans-serif !important;
}
</style>
<style>
.v-parallax__content {
  background-color: rgba(0, 0, 0, 0.6);
}
</style>
